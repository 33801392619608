import React, { useContext, useRef } from "react";
import clsx from "clsx";
import MuiDrawer from "@material-ui/core/Drawer";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MentalMetrixWhiteLogo from "modules/components/images/mentalMetrixWhiteLogo/MentalMetrixWhiteLogo";
import DashboardIcon from "../../../assets/DashboardIcon.svg";
import Athlete from "../../../assets/Athlete.svg";
import profileFinderIcon from "../../../assets/profileFinder.svg";
import consentIcon from "../../../assets/consent.svg";
import coachIcon from "../../../assets/whistle.svg";
import trophyIcon from "../../../assets/trophy.svg";
import AddAthleteIcon from "../../../assets/AddAthlete.svg";
import AccountManagement from "../../../assets/account-management.svg";
import AssessmentComment from "../../../assets/assessment-comments.svg";
import ConferenceManagement from "../../../assets/conference.svg";
import AddCoachIcon from "../../../assets/AddCoach.svg";
import SportAndPosition from "../../../assets/sports-and-positions.svg";
import menu from "../../../assets/menu-dots.svg";
import DrawerItem from "./DrawerItem";
import { accountTypes } from "../../../store/constants";
import { useHistory, useLocation } from "react-router-dom";
import { ActionContext } from "../../../contexts";
import { connect } from "react-redux";
import { IconButton, List, makeStyles, Toolbar } from "@material-ui/core";
import CodeIcon from "@material-ui/icons/Code";
import "../../../index.css";
import MentalMetrixInfinitySign from "../../components/images/mentalMetrixInfinitySign/MentalMetrixInfinitySign";
import appConfig from "../../../config";

let useStyles = makeStyles((theme) => ({
  drawer: {
    width: appConfig.drawer.maxWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: theme.colors.primaryNavy,

  },
  drawerOpen: {
    width: appConfig.drawer.maxWidth,
    background: theme.colors.primaryNavy,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
  },
  smallLogo: {
    paddingLeft: "0.2rem",
  },
  codeAlignData: {
    marginTop: 'revert',
    display: "flex",
    width: "fit-content",
    background: theme.colors.white,
    marginLeft: "auto",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",

  },
  image: {
    cursor: "pointer",
  },
  drawerClose: {
    overflowX: "hidden",
    width: appConfig.drawer.minWidth,
    background: theme.colors.primaryNavy,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  drawerPaper: {
    width: appConfig.drawer.maxWidth,
    overflow: "hidden",
  },
  content: {
    background: theme.colors.primaryNavy,
    height: "100%",
  },
  hide: {
    display: "none",
  },
  listItemIcon: {
    display: "flex",
    justifyContent: "center",
    marginRight: "0.5rem",
  },
  listItemText: {
    fontSize: '1rem',
    fontWeight: 700,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  active: {
    color: theme.colors.white,
  },
  line: {
    borderBottom: `1px solid ${theme.colors.darkBlue}`,
    margin: "1rem 0rem",
    paddingLeft: 13,
    paddingRight: "0.8rem",
  },
  drawerButton: {
    color: theme.colors.primaryOrange
  }
}));

const Drawer = (props) => {
  let history = useHistory();
  let location = useLocation();
  let classes = useStyles();
  let fileInputRef = useRef();

  let { isDrawerOpen, accountType } = props;
  let { appActions } = useContext(ActionContext);

  let handleDrawerClick = () => {
    appActions.toggleDrawer();
  };

  let handleFileSelect = (e) => {
    let data = new FormData();
    data.append("players_file", e.target.files[0]);
    const cb = (fn) => {
      return () => {
        fileInputRef.current.value = null;
        fn();
      };
    };
  };

  let dashboard = {
    name: "Dashboard",
    icon: DashboardIcon,
    route: "/",
    onClick: () => {
      history.push("/");
    },
  };

  let contacts = {
    name: "Profile Finder",
    icon: profileFinderIcon,
    route: "/profile-finder",
    onClick: () => {
      history.push("/profile-finder");
    },
  };

  let createAthlete = {
    name: "Add Athlete",
    icon: AddAthleteIcon,
    route: "/create-athlete",
    onClick: () => {
      history.push("/create-athlete");
    },
  };

  let coachCreation = {
    name: "Add Coach",
    icon: AddCoachIcon,
    route: "/create-coach",
    onClick: () => {
      history.push("/create-coach");
    },
  };

  let accountManagement = {
    name: "Account Management",
    icon: AccountManagement,
    route: "/account-management",
    onClick: () => {
      history.push("/account-management");
    },
  };

  let conferenceManagement = {
    name: "Conference Management",
    icon: ConferenceManagement,
    route: "/conference",
    onClick: () => {
      history.push("/conference");
    },
  };

  let sportsManagement = {
    name: "Sports & Positions",
    icon: SportAndPosition,
    route: "/sports-positions",
    onClick: () => {
      history.push("/sports-positions");
    },
  };

  let infoManagement = {
    name: "More Info",
    icon: menu,
    route: "/more-info",
    onClick: () => {
      history.push("/more-info");
    },
  };

  let coachManagement = {
    name: "Coach",
    icon: coachIcon,
    route: "/coach",
    onClick: () => {
      history.push("/coach");
    },
  };

  let consentManagement = {
    name: "Consent",
    icon: consentIcon,
    route: "/consent",
    onClick: () => {
      history.push("/consent");
    },
  };

  let teamManagement = {
    name: "Team",
    icon: trophyIcon,
    route: "/team-overview",
    onClick: () => {
      history.push("/team-overview");
    },
  };

  let newAthleteManagement = {
    name: "Athlete",
    icon: Athlete,
    route: "/athlete-management",
    onClick: () => {
      history.push("/athlete-management");
    },
  };

  let assessmentComments = {
    name: 'Assessment Attributes',
    icon: AssessmentComment,
    route: '/assessment-comments',
    onClick: () => {
      history.push('/assessment-comments')
    },
  };

  const topDrawerItems = {
    [accountTypes.superAdmin]: {
      dashboard,
      newAthleteManagement,
      coachManagement,
      consentManagement,
      contacts,
      conferenceManagement,
      sportsManagement
    },
    [accountTypes.mmDdiAdmin]: {
      dashboard,
      newAthleteManagement,
      coachManagement,
      consentManagement,
      contacts,
      conferenceManagement,
      sportsManagement
    },
    [accountTypes.mmPsychologist]: {
      dashboard,
      newAthleteManagement,
      coachManagement,
      contacts
    },
    [accountTypes.mmChiefPsychologist]: {
      dashboard,
      newAthleteManagement,
      coachManagement,
      contacts
    },
    [accountTypes.orgAdmin]: {
      dashboard,
      newAthleteManagement,
      consentManagement
    },
    [accountTypes.orgPsychologist]: {
      dashboard,
      newAthleteManagement
    },
    [accountTypes.orgUser]: {
      dashboard,
      newAthleteManagement
    },
    [accountTypes.candidate]: {
      dashboard,
    },
    [accountTypes.colAthleticDirector]: {
      dashboard,
      newAthleteManagement,
      coachManagement
    },
    [accountTypes.colHeadCoach]: {
      dashboard,
      newAthleteManagement,
      teamManagement,
      coachManagement
    },
    [accountTypes.colPositionCoach]: {
      newAthleteManagement,
      teamManagement
    },
    [accountTypes.colPsychologist]: {
      newAthleteManagement,
      teamManagement,
      coachManagement,
      contacts
    },
    [accountTypes.proGeneralManager]: {
      dashboard,
      newAthleteManagement,
      teamManagement,
      coachManagement
    },
    [accountTypes.proHeadCoach]: {
      dashboard,
      newAthleteManagement,
      teamManagement,
      coachManagement
    },
    [accountTypes.proPositionCoach]: {
      newAthleteManagement,
      teamManagement
    },
    [accountTypes.proScouts]: {
      teamManagement,
      newAthleteManagement
    },
    [accountTypes.proSecurity]: {
      newAthleteManagement,
      teamManagement
    },
    [accountTypes.proPsychologist]: {
      newAthleteManagement,
      teamManagement,
      coachManagement,
      contacts
    },
  };

  const bottomDrawerItems = {
    [accountTypes.superAdmin]: {
      createAthlete,
      coachCreation,
      accountManagement,
      assessmentComments,
      infoManagement,
    },
    [accountTypes.mmDdiAdmin]: {
      createAthlete,
      coachCreation,
      accountManagement,
      infoManagement,
    },
    [accountTypes.mmPsychologist]: {
      infoManagement,
    },
    [accountTypes.mmChiefPsychologist]: {
      infoManagement,
    },
    [accountTypes.orgAdmin]: {
      createAthlete,
      infoManagement,
    },
    [accountTypes.orgPsychologist]: {
      infoManagement,
    },
    [accountTypes.orgUser]: {
      createAthlete,
      infoManagement,
    },
    [accountTypes.candidate]: {},
    [accountTypes.colAthleticDirector]: {
      coachCreation,
      infoManagement,
    },
    [accountTypes.colHeadCoach]: {
      createAthlete,
      coachCreation,
      infoManagement,
    },
    [accountTypes.colPositionCoach]: {
      createAthlete,
      infoManagement,
    },
    [accountTypes.colPsychologist]: {
      infoManagement,
    },
    [accountTypes.proGeneralManager]: {
      createAthlete,
      coachCreation,
      infoManagement,
    },
    [accountTypes.proHeadCoach]: {
      createAthlete,
      coachCreation,
      infoManagement,
    },
    [accountTypes.proPositionCoach]: {
      createAthlete,
      infoManagement,
    },
    [accountTypes.proScouts]: {
      createAthlete,
      infoManagement,
    },
    [accountTypes.proSecurity]: {
      createAthlete,
      infoManagement,
    },
    [accountTypes.proPsychologist]: {
      infoManagement,
    },
  };

  return (
    <MuiDrawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isDrawerOpen,
        [classes.drawerClose]: !isDrawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        }),
      }}
    >
      <input
        type="file"
        accept=".csv"
        ref={fileInputRef}
        onChange={handleFileSelect}
        style={{ display: "none" }}
      />

      <div className={classes.content}>
        { isDrawerOpen ? <div className={classes.logo}>
          <MentalMetrixWhiteLogo width={200}/>
        </div> : <div >
          <MentalMetrixInfinitySign className={classes.smallLogo} />
        </div>
        }
        <List>
          {Object.values(topDrawerItems[accountType]).map((drawer, index) => {
            let { onClick, icon: Icon, name, route } = drawer;
            let selected = location.pathname === route;

            return (
              <DrawerItem
                selected={selected}
                button
                onClick={onClick}
                key={index}
                disableRipple
              >
                <ListItemIcon
                  className={clsx(classes.listItemIcon, {
                    [classes.active]: selected,
                  })}
                >
                  <img src={Icon} alt="" style={{ width: "18px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={name}
                  primaryTypographyProps={{
                    className: clsx(
                      classes.listItemText,
                      classes.drawerCloselogo,
                      {
                        [classes.active]: selected,
                      }
                    ),
                  }}
                />
              </DrawerItem>
            );
          })}
        </List>
        <div className={classes.line}></div>
        <List>
          {Object.values(bottomDrawerItems[accountType]).map(
            (drawer, index) => {
              let { onClick, icon: Icon, name, route } = drawer;
              let selected = location.pathname === route;

              return (
                <DrawerItem
                  selected={selected}
                  button
                  onClick={onClick}
                  key={index}
                  disableRipple
                >
                  <ListItemIcon
                    className={clsx(classes.listItemIcon, {
                      [classes.active]: selected,
                    })}
                  >
                    <img src={Icon} alt="" style={{ width: "18px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={name}
                    primaryTypographyProps={{
                      className: clsx(
                        classes.listItemText,
                        classes.drawerCloselogo,
                        {
                          [classes.active]: selected,
                        }
                      ),
                    }}
                  />
                </DrawerItem>
              );
            }
          )}
        </List>
        <div className={classes.codeAlignData}>
          <Toolbar
            variant="dense"
            disableGutters
            style={{
              display: "flex",
              justifyContent: "end"
            }}
          >
            <IconButton size="small" onClick={handleDrawerClick}>
              <CodeIcon className={classes.drawerButton}/>
            </IconButton>
          </Toolbar>
        </div>
      </div>
    </MuiDrawer>
  );
};

let mapStateToProps = (state) => {
  return {
    isDrawerOpen: state.ui.app.isDrawerOpen,
    accountType: state.account.accountType,
  };
};

export default connect(mapStateToProps)(Drawer);
