import React, {useContext, useEffect, useState} from "react";
import ExpansionArticle from "../ExpansionArticle";
import ChartAndDetails from "../ChartAndDetails";
import { connect } from "react-redux";
import { Box, makeStyles, Typography } from "@material-ui/core";
import {ChildTab, MainTab, TabPanel} from "../../../shared/Tabs";
import {LinearBar} from "../../../shared/Charts";
import {ActionContext} from "../../../../contexts";
import {CircularProgress} from "../../../shared";

let useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    container: {
        flexGrow: 1,
        display: "flex",
        margin: "0px",
        backgroundColor: theme.palette.common.white,
        boxShadow: "3px 3px 10px 3px #cdcdcd",
        borderRadius: 8,
    },
    sideBarTitle: {
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: '1rem',
        fontWeight: 700,
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
    },
    selectedSideBarTitle: {
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: '1rem',
        fontWeight: 700,
        textTransform: 'capitalize',
        color: theme.palette.primary.main,
        backgroundColor: theme.colors.veryLightGrey,
    },
    tabPanel: {
        backgroundColor: theme.palette.common.white,
        width: '85%',
        padding: '2rem 1rem',
        borderRadius: "0px 8px 8px 0px",
    },
    content: {
        backgroundColor: theme.colors.veryLightGrey,
        width: '90%',
        alignSelf: 'center',
        marginBottom: '1rem',
        borderRadius: 5,
    },
    gauges: {
        marginBottom: 48,
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly",
    },
    gaugeContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: '100%',
    },
    subTraits: {
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: 16,
    },
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Attributes = (props) => {
  let classes = useStyles();
  const { assessmentCommentActions } = useContext(ActionContext);
  let { colors, customTraits, loadingColors } = props;
  const [selectedPage, setSelectedPage] = useState(0);

  useEffect(() => {
      assessmentCommentActions.attributeColors();
  }, []);

  const handleChange = (event, newValue) => {
    setSelectedPage(newValue);
  };

  return (
      <Box className={classes.container}>
          {
              loadingColors && loadingColors ? (
                  <Box>
                    <CircularProgress />
                  </Box>
              ) : (
                  <>
                      <MainTab
                          orientation="vertical"
                          variant="scrollable"
                          value={selectedPage}
                          onChange={handleChange}
                          aria-label="Vertical tabs example"
                          style={{ width: 250 }}
                      >
                          <ChildTab
                              className={selectedPage === 0 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                              label="Character"
                              {...a11yProps(0)}
                          />
                          <ChildTab
                              className={selectedPage === 1 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                              label="Coachability"
                              {...a11yProps(1)}
                          />
                          <ChildTab
                              className={selectedPage === 2 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                              label="Composure"
                              {...a11yProps(2)}
                          />
                          <ChildTab
                              className={selectedPage === 3 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                              label="Leadership"
                              {...a11yProps(3)}
                          />
                      </MainTab>
                      <TabPanel
                          value={selectedPage}
                          index={0}
                          className={classes.tabPanel}
                      >
                          <div>
                              <ExpansionArticle
                                  title={customTraits["character"]["label"]}
                                  details={customTraits["character"]["description"]}
                              >
                                  <div className={classes.content}>
                                      <ChartAndDetails
                                          diagnosis={customTraits["character"]["text"]}
                                          score={customTraits["character"]["score"]}
                                          gradientColors={colors.character}
                                      />
                                      <div className={classes.gauges}>
                                          {Object.values(customTraits["character"].sub).map(
                                              (subTrait, index) => {
                                                  return (
                                                      <div key={index} className={classes.gaugeContainer}>
                                                          <LinearBar score={subTrait["score"]} gradientColors={colors.character}/>
                                                          <Typography className={classes.subTraits}>
                                                              {`+ ${subTrait["left_label"]}`}
                                                          </Typography>
                                                      </div>
                                                  );
                                              }
                                          )}
                                      </div>
                                  </div>
                              </ExpansionArticle>
                          </div>
                      </TabPanel>
                      <TabPanel
                          value={selectedPage}
                          index={1}
                          className={classes.tabPanel}>
                          <div>
                              <ExpansionArticle
                                  title={customTraits["coachability"]["label"]}
                                  details={customTraits["coachability"]["description"]}
                              >
                                  <div className={classes.content}>
                                      <ChartAndDetails
                                          diagnosis={customTraits["coachability"]["text"]}
                                          score={customTraits["coachability"]["score"]}
                                          gradientColors={colors.coachability}
                                      />
                                      <div className={classes.gauges}>
                                          {Object.values(customTraits["coachability"].sub).map(
                                              (subTrait, index) => {
                                                  return (
                                                      <div key={index} className={classes.gaugeContainer}>
                                                          <LinearBar score={subTrait["score"]} gradientColors={colors.coachability}/>
                                                          <Typography className={classes.subTraits}>
                                                              {`+ ${subTrait["left_label"]}`}
                                                          </Typography>
                                                      </div>
                                                  );
                                              }
                                          )}
                                      </div>
                                  </div>
                              </ExpansionArticle>
                          </div>
                      </TabPanel>
                      <TabPanel
                          value={selectedPage}
                          index={2}
                          className={classes.tabPanel}
                      >
                          <div>
                              <ExpansionArticle
                                  title={customTraits["composure"]["label"]}
                                  details={customTraits["composure"]["description"]}
                              >
                                  <div className={classes.content}>
                                      <ChartAndDetails
                                          diagnosis={customTraits["composure"]["text"]}
                                          score={customTraits["composure"]["score"]}
                                          gradientColors={colors.composure}
                                      />
                                      <div className={classes.gauges}>
                                          {Object.values(customTraits["composure"].sub).map(
                                              (subTrait, index) => {
                                                  return (
                                                      <div key={index} className={classes.gaugeContainer}>
                                                          <LinearBar score={subTrait["score"]} gradientColors={colors.coachability}/>
                                                          <Typography className={classes.subTraits}>
                                                              {`+ ${subTrait["left_label"]}`}
                                                          </Typography>
                                                      </div>
                                                  );
                                              }
                                          )}
                                      </div>
                                  </div>
                              </ExpansionArticle>
                          </div>
                      </TabPanel>
                      <TabPanel
                          value={selectedPage}
                          index={3}
                          className={classes.tabPanel}
                      >
                          <div>
                              <ExpansionArticle
                                  title={customTraits["leadership"]["label"]}
                                  details={customTraits["leadership"]["description"]}
                              >
                                  <div className={classes.content}>
                                      <ChartAndDetails
                                          diagnosis={customTraits["leadership"]["text"]}
                                          score={customTraits["leadership"]["score"]}
                                          gradientColors={colors.leadership}
                                      />
                                      <div className={classes.gauges}>
                                          {Object.values(customTraits["leadership"].sub).map(
                                              (subTrait, index) => {
                                                  return (
                                                      <div key={index} className={classes.gaugeContainer}>
                                                          <LinearBar score={subTrait["score"]} gradientColors={colors.coachability}/>
                                                          <Typography className={classes.subTraits}>
                                                              {`+ ${subTrait["left_label"]}`}
                                                          </Typography>
                                                      </div>
                                                  );
                                              }
                                          )}
                                      </div>
                                  </div>
                              </ExpansionArticle>
                          </div>
                      </TabPanel>
                  </>
              )
          }
      </Box>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { playerId } = ownProps;

  return {
    customTraits: state.player.customTraits[playerId],
    team: state.account.team,
    loadingColors: state.ui.assessmentComments.loadingAttributeColors,
    colors: state.assessmentComments.getAttributeColors,
  };
};

export default connect(mapStateToProps)(Attributes);
